import {
  CoralConfigOptions,
  CoralColors,
  octopusTheme,
} from '@krakentech/coral';
import { rgba } from 'polished';

import { kfuTypography } from '@/styles/typography';
import { convertHexToRgbaWithOpacity } from '@/utils/styles';

import {
  commonColors,
  alertBackgroundColors,
  black,
  textColors,
  actionColors,
  baseColors,
  datePickerStyles,
  stepLineBorderWidth,
  stepLabelColor,
  buttonFocusOutline,
  white,
  ExtendedCoralStyleProperties,
} from './common';

const leepColors: CoralColors = {
  ...commonColors,
  primary: {
    main: '#0773BA',
    main10: 'rgba(7, 115, 186, 0.1)',
    light: '#73B0D8',
    dark: '#065C95',
    intense: '#044570',
  },
  secondary: {
    main: '#002A47',
    main10: 'rgba(0, 42, 71, 0.1)',
    light: '#839DAF',
    dark: '#001C2F',
  },
  tertiary: {
    main: '#23AF2C',
    light: '#7BCF80',
    light10: 'rgba(35, 175, 44, 0.1)',
  },
  surface: {
    main: '#ffffff',
    light: '#E9E9EC',
    mid: '#ffffff',
    dark: '#ffffff',
  },
  text: {
    main: '#1E0D35',
    mid: '#A3A0AC',
    mid20: 'rgba(163, 160, 172, 0.2)',
    light: '#1E0D35', // This needs to be a darker colour because the one place we're using this is the desktop navs. On Leep the nav is white so this needs to be a dark colour.
    dark: '#696176',
  },
};

const borderWidth = '0.1rem';
const borderRadius = '0.4rem';
const cardBorderWidth = '0.2rem';

const buttonBorderRadius = '1rem';
const cardBorderRadius = '1.2rem';
const boxShadow = `0 ${cardBorderWidth} 0.6rem 0 ${rgba(black, 0.1)}`;

const theme: Partial<CoralConfigOptions> = {
  overrides: {
    animation: {
      easing: 'ease-out',
      durationMs: 250,
    },
    borderWidth,
    borderRadius,
    color: leepColors,
    components: {
      alert: {
        root: {
          padding: `${octopusTheme.spacing.xsSm} ${octopusTheme.spacing.smMd}`,
          border: 'none',
          borderRadius: cardBorderRadius,
          color: textColors.main,
        },
        ...alertBackgroundColors,
      },
      banner: {
        root: {
          background: actionColors.successLight,
        },
      },
      button: {
        root: {
          borderRadius: buttonBorderRadius,
        },
        primary: {
          contained: {
            color: `${leepColors.surface.main}!important`,
            '& svg path': {
              fill: white,
            },
            ':hover': {
              backgroundColor: leepColors.primary.intense,
            },
            ':disabled': {
              backgroundColor: leepColors.primary.light,
              borderColor: leepColors.primary.light,
            },
            ':focus': {
              borderColor: leepColors.primary.main,
              ...buttonFocusOutline(textColors.main),
            },
          } as ExtendedCoralStyleProperties,
          outlined: {
            ':hover': {
              color: `${leepColors.surface.main}!important`,
            },
            ':focus': {
              color: `${leepColors.surface.main}!important`,
              ...buttonFocusOutline(leepColors.primary.main),
            },
          },
          text: {
            ':hover': {
              color: leepColors.text.main,
            },
          },
          link: {
            padding: '0',
          },
        },
        secondary: {
          contained: {
            color: `${leepColors.surface.main}!important`,
            ':hover': {
              backgroundColor: leepColors.secondary.dark,
            },
            ':focus': {
              borderColor: white,
              ...buttonFocusOutline(leepColors.secondary.main),
            },
          },
          outlined: {
            ':hover': {
              color: leepColors.surface.main,
            },
            ':focus': {
              color: leepColors.surface.main,
              borderColor: white,
              ...buttonFocusOutline(leepColors.secondary.main),
            },
          },
          ghost: {
            '& svg path': {
              fill: black,
            },
          } as ExtendedCoralStyleProperties,
        },
        tertiary: {
          contained: {
            backgroundColor: leepColors.primary.main10,
            color: `${leepColors.primary.intense}!important`,
            border: 'none',
            '& svg path': {
              fill: leepColors.primary.intense,
            },
            ':hover': {
              backgroundColor: leepColors.secondary.main,
              color: `${actionColors.infoLight}!important`,
              '& svg path': {
                fill: actionColors.infoLight,
              },
            },
            ':focus-within': {
              backgroundColor: leepColors.text.main,
              color: `${leepColors.surface.main}!important`,
              '& svg path': {
                fill: leepColors.surface.main,
              },
            },
            ':disabled': {
              backgroundColor: leepColors.text.mid20,
            },
          } as ExtendedCoralStyleProperties,
          ghost: {
            ':hover': {
              backgroundColor: actionColors.infoLight,
              color: leepColors.text.main,
            },
          },
        },
      },
      card: {
        root: {
          boxShadow,
          borderRadius: cardBorderRadius,
          borderWidth: cardBorderWidth,
        },
        image: {
          borderTopLeftRadius: borderRadius,
          borderTopRightRadius: borderRadius,
        },
        header: {
          color: leepColors.surface.main,
        },
        light: {
          backgroundColor: baseColors.dark,
          color: leepColors.text.main,
        },
        mid: {
          backgroundColor: leepColors.surface.mid,
          color: leepColors.surface.main,
        },
        dark: {
          backgroundColor: leepColors.surface.dark,
          color: leepColors.surface.main,
        },
      },
      checkbox: {
        root: {
          span: {
            fontSize: `${octopusTheme.typography.body1.baseStyles.fontSize}`,
          },
          'input:checked + div': {
            backgroundColor: leepColors.primary.main,
          },
          'input + div': {
            '&:after': {
              backgroundColor: convertHexToRgbaWithOpacity(
                leepColors.primary.main,
                0.3
              ),
            },
          },
          'svg path': {
            fill: leepColors.surface.main,
          },
        } as ExtendedCoralStyleProperties,
        indicator: {
          border: `0.2rem solid ${leepColors.primary.main}`,
        },
      },
      dialog: {
        scrollWrapper: {
          border: `${borderWidth} solid ${leepColors.surface.mid}`,
        },
        content: {
          '> div': {
            '> div': {
              // removes bottom margin from close cross
              marginBottom: 0,
              'svg path': {
                fill: leepColors.primary.main,
              },
            },
          },
        } as ExtendedCoralStyleProperties,
      },
      container: {
        light: {
          backgroundColor: leepColors.text.main,
          color: leepColors.surface.main,
        },
        mid: {
          backgroundColor: leepColors.surface.main,
        },
        dark: {
          backgroundColor: baseColors.main,
        },
      },
      error: {
        root: {
          background: white,
          borderRadius: cardBorderRadius,
          boxShadow,
        },
      },
      loader: {
        linear: {
          root: {
            backgroundColor: baseColors.main,
          },
        },
      },
      datepicker: {
        calendar: {
          ...datePickerStyles,
          '.rdp-caption': {
            backgroundColor: leepColors.primary.main,
          },
          '.rdp-year_month-button:hover': {
            backgroundColor: leepColors.tertiary.main,
            color: white,
          },
          '.rdp-caption_label span, .rdp-nav button, .rdp-caption button': {
            color: white,
          },
          '.rdp-month, .rdp-month-only, .rdp-year-only': {
            backgroundColor: white,
          },
          '.rdp-day_selected, .rdp-year_month-button--selected': {
            color: leepColors.primary.main,
            borderColor: leepColors.tertiary.main,
          },
          '.rdp-button.rdp-day:hover:not([disabled]):not(.rdp-day_selected), .rdp-day:focus-visible:not([disabled]), .rdp-day_selected:hover, .rdp-year_month-button:focus-visible':
            {
              backgroundColor: leepColors.tertiary.main,
              color: textColors.light,
              outlineColor: leepColors.tertiary.main,
              border: 'none',
            },
        },
      },
      stepper: {
        stepGroup: {
          ...stepLabelColor,
        } as ExtendedCoralStyleProperties,
        stepDisc: {
          root: {
            background: textColors.mid20,
            color: textColors.mid,
          },
          isCurrent: {
            background: leepColors.primary.main,
            borderColor: leepColors.primary.main,
            color: textColors.light,
          },
          isComplete: {
            background: leepColors.tertiary.main,
            borderColor: leepColors.tertiary.main,
          },
        },
        stepLine: {
          root: {
            borderWidth: stepLineBorderWidth,
            borderColor: textColors.mid,
          },
          isCurrent: {
            borderColor: leepColors.tertiary.main,
          },
          isComplete: {
            borderColor: leepColors.tertiary.main,
          },
        },
      },
      switch: {
        root: {
          'input[disabled] ~ span[data-testid="switch__handle"]': {
            color: `${leepColors.primary.light} !important`,
          },
          'input[disabled] ~ span[data-testid="switch__track"]': {
            background: `${leepColors.primary.light} !important`,
          },
        } as ExtendedCoralStyleProperties,
        track: {
          background: `${leepColors.surface.light} !important`,
        },
        handle: {
          color: `${leepColors.primary.main} !important`,
          zIndex: 0,
        },
        checked: {
          track: {
            background: `${leepColors.primary.light} !important`,
          },
        },
      },
      toast: {
        root: {
          color: textColors.main,
        },
      },
      tooltip: {
        tooltipContent: {
          color: leepColors.surface.main,
        },
        button: {
          '& svg path': {
            fill: leepColors.primary.main,
          },
          p: {
            color: leepColors.primary.main,
          },
        } as ExtendedCoralStyleProperties,
      },
      typography: {
        link: {
          color: leepColors.primary.main,
          ':hover': {
            color: leepColors.primary.dark,
          },
        },
      },
    },
    name: 'Leep Theme',
    spacing: {
      ...octopusTheme.spacing,
    },
    breakpoints: {
      ...octopusTheme.breakpoints,
      md: 767,
    },
    typography: {
      ...octopusTheme.typography,
      ...kfuTypography,
      fontFamily: 'Calibri, Arial, helvetica, arial, sans-serif',
    },
  },
  defaultProps: {
    card: {
      theme: 'light',
      padding: 'medium',
      borderless: true,
    },
    blockquote: {
      gap: 'lg',
    },
    button: {
      size: 'small',
    },
    loader: {
      color: 'primary',
    },
    radio: {
      theme: 'light',
    },
    skeleton: {
      animation: 'pulse',
    },
    textfield: {
      theme: 'dark',
    },
    tooltip: {
      theme: 'light',
    },
    typography: {
      color: 'inherit',
    },
  },
};

export default theme;
